<template>
  <div>
    <div
      class="card d-flex"
      v-for="(item, index) in 8"
      :key="'shimmer' + index"
    >
      <div class="img-wrapper">
        <Shimmer height="100%" width="100%" class="" />
      </div>
      <div class="card-body d-flex flex-column justify-content-between">
        <div class="d-flex justify-content-between mb-2">
          <Shimmer width="40%" height="20px" class="shimmer is-desktop" />
          <Shimmer width="40%" height="20px" class="shimmer is-desktop" />
          <Shimmer width="40%" height="12px" class="shimmer is-mobile" />
          <Shimmer width="40%" height="12px" class="shimmer is-mobile" />
        </div>
        <Shimmer width="90%" height="40px" class="shimmer mb-2 is-desktop" />
        <Shimmer width="80%" height="40px" class="shimmer mb-2 is-desktop" />
        <Shimmer width="35%" height="20px" class="shimmer mb-2 is-desktop" />
        <Shimmer width="90%" height="25px" class="shimmer mb-2 is-mobile" />
        <Shimmer width="80%" height="25px" class="shimmer mb-2 is-mobile" />
        <Shimmer width="35%" height="12px" class="shimmer mb-2 is-mobile" />
        <div class="d-flex justify-content-between">
          <Shimmer width="50%" height="20px" class="shimmer is-desktop" />
          <Shimmer width="30%" height="20px" class="shimmer is-desktop" />
          <Shimmer width="50%" height="12px" class="shimmer is-mobile" />
          <Shimmer width="30%" height="12px" class="shimmer is-mobile" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Shimmer from "../Shimmer";
export default {
  components: { Shimmer },
};
</script>

<style lang="scss" scoped>
div {
  .card {
    border-radius: 10px;
    width: 75%;
    height: 200px;
    margin-bottom: 20px;
    flex-direction: row;
    overflow: hidden;
    margin-right: 20px;
    @media only screen and (max-width: 1024px) {
      width: 100%;
      height: 150px;
    }
    .img-wrapper {
      width: 35%;
      height: 200px;
    }
    .card-body {
      width: 65%;
    }
    .shimmer {
      border-radius: 5px;
      @media only screen and (max-width: 1024px) {
        border-radius: 2px;
      }
    }
  }
}
</style>
