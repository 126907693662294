@@ -1,80 +0,0 @@
<template>
  <div class="card-wrapper">
    <div class="card_" v-for="item in 4" :key="item">
      <Shimmer height="100%" width="100%" />
    </div>
  </div>
</template>

<script>
import Shimmer from "@/components/Shimmer"

export default {
  components: {
    Shimmer
  }
}
</script>

<style lang="scss" scoped>
.card-wrapper {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 16px;
  width: 100%;
  @media only screen and (max-width: 1024px) {
    grid-template-columns: repeat(1, 1fr);
  }
  .card_ {
    width: 100%;
    height: 300px;
    border-radius: 10px;
    margin-right: 16px;
    overflow: hidden;
    position: relative;
  }
}
</style>
