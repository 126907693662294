@@ -1,80 +0,0 @@
<template>
  <div class="card">
    <div class="img-wrapper">
      <Shimmer height="140px" width="100%" />
    </div>
    <div class="card-body d-flex flex-column justify-content-between">
      <div class="d-flex justify-content-between mb-3">
        <Shimmer width="100px" height="20px" class="btn1 shimmer" />
        <Shimmer width="100px" height="20px" class="btn2 shimmer" />
      </div>
      <Shimmer width="100%" height="50px" class="body shimmer" />
    </div>
  </div>
</template>

<script>
import Shimmer from "@/components/Shimmer"

export default {
  components: {
    Shimmer
  }
}
</script>

<style lang="scss" scoped>
.card {
  width: 280px;
  border-radius: 10px;
  margin-right: 16px;
  overflow: hidden;
}
</style>
