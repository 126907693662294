<template>
  <div>
    <Shimmer width="100%" height="500px" class="shimmer" />
  </div>
</template>

<script>
import Shimmer from "../Shimmer";
export default {
  components: { Shimmer },
};
</script>

<style lang="scss" scoped>
::v-deep span {
  border-radius: 5px;
  margin-bottom: 20px;
  @media only screen and (max-width: 1024px) {
    height: 200px !important;
  }
}
</style>
