@@ -1,80 +0,0 @@
<template>
  <div class="card">
    <div class="img-wrapper">
      <Shimmer height="160px" width="100%" class="shimmer" />
    </div>
    <div class="card-body d-flex flex-column justify-content-between">
      <div class="d-flex justify-content-between mb-3 desktop">
        <Shimmer width="100px" height="20px" class="btn1 shimmer" />
        <Shimmer width="100px" height="20px" class="btn2 shimmer" />
      </div>
      <Shimmer width="50%" height="20px" class="shimmer mb-2 is-mobile" />
      <Shimmer width="100%" height="50px" class="body shimmer mb-2" />
      <Shimmer width="70%" height="20px" class="shimmer is-mobile" />
    </div>
  </div>
</template>

<script>
import Shimmer from "@/components/Shimmer"

export default {
  components: {
    Shimmer
  }
}
</script>

<style lang="scss" scoped>
.card {
  width: 280px;
  border-radius: 10px;
  margin-right: 16px;
  overflow: hidden;
  @media only screen and (max-width: 1024px) {
    width: 100%;
    margin: 0;
    flex-direction: row;
    height: 135px;
  }
  .img-wrapper {
    @media only screen and (max-width: 1024px) {
      width: 40%;
      ::v-deep .shimmer {
        height: 100% !important;
      }
    }
  }
  .card-body {
    @media only screen and (max-width: 1024px) {
      width: 60%;
    }
    ::v-deep .desktop {
      @media only screen and (max-width: 1024px) {
        display: none !important;
      }
    }
  }
}
</style>
