<template>
  <div class="card-wrapper">
    <Shimmer
      v-for="(item, index) in 5"
      :key="index + 'kenali-mentor'"
      width="300px"
      height="320px"
      class="shimmer is-desktop"
    />
    <Shimmer
      v-for="(item, index) in 2"
      :key="index + 'kenali-mentor-2'"
      width="100%"
      height="250px"
      class="shimmer is-mobile"
    />
  </div>
</template>

<script>
import Shimmer from "../Shimmer";
export default {
  components: { Shimmer },
};
</script>

<style lang="scss" scoped>
.card-wrapper {
  width: 100%;
  display: flex;
  .shimmer {
    border-radius: 10px;
    margin-right: 20px;
  }
}
</style>
