<template>
  <div class="imsak-wrapper">
    <div class="container">
      <div class="imsak">
        <div class="info">
          <img class="logo" src="/storage/images/ramadan/logo-narasi-ramadhan.svg" alt="Narasi Ramadhan" />
          <span class="description">Maknai keberkahan Ramadan dengan sukacita sembari menikmati beragam informasi yang kami sajikan khusus untuk Anda.</span>
          <nuxt-link class="cta" to="/religi">
            <span>Selengkapnya</span>
            <img src="/storage/images/ramadan/arrow-right.svg" alt="">
          </nuxt-link>
        </div>
        <div class="imsak-quran">
          <div class="imsak-card">
            <div class="title">
              <img src="/storage/images/ramadan/clock-mejid.png" alt="clock-mejid">
              <span>JADWAL SHOLAT & IMSAKIYAH</span>
            </div>
            <div class="options">
              <v-select :clearable="false" v-model="city" placeholder="Pilih kota" label="kabkoNama"
                :options="$store.state.ramadan.kota.items"></v-select>
              <date-picker class="date-picker" v-model="date" :masks="masks" locale="id">
                <template v-slot="{ inputValue, togglePopover }">
                  <div class="selected-date" @click="togglePopover()">
                    <div locale="id" class="value-date">
                      {{ inputValue }}
                    </div>
                    <Icons name="calendar-dots" class="calendar-icon" />
                  </div>
                </template>
              </date-picker>
            </div>
            <div class="imsak-card-wrapper">
              <div class="imsak-card-item" v-for="(item, index) in $store.state.ramadan.jadwal.items" :key="`imsak-card-item-${index}`">
                <span>{{index}}</span>
                <span>{{$store.state.ramadan.jadwal.items[index]}}</span>
              </div>
            </div>
          </div>
          <nuxt-link to="/religi/quran-digital" class="quran-card">
            <img src="/storage/images/ramadan/book.svg" alt="book">
            <span>Mulai baca Qur’an</span>
            <img src="/storage/images/ramadan/arrow-right.svg" alt="arrow">
          </nuxt-link>
        </div>
        <div class="articles">
          <div class="articles-wrapper" v-swiper:articleNrSwipper="articleSwipperOption">
            <div class="swiper-wrapper">
              <nuxt-link class="swiper-slide article-item"
                v-for="(article, index) in $store.state.articles.articles_tags_infinity.items"
                :key="`article-item-${index}`" :to="{
                name: article.isText
                  ? 'read-channel-slug'
                  : 'video-channel-slug',
                params: {
                  channel: article.channel ? article.channel.slug : '',
                  slug: article.slug,
                },
              }">
                <ImageResponsive width="252" height="141" :imageUrl="article.thumbnail.large" :fromUrl="true"
                  class="article-image" />
                <span>{{ article.title }}</span>
              </nuxt-link>
            </div>
            <div class="prev-slide-article">
              <Icons name="arrow-left" />
            </div>
            <div class="next-slide-article">
              <Icons name="arrow-right" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ImageResponsive from "../ImageResponsive.vue";
import Icons from "../Icons.vue";
import config from "../../config";

export default {
  components: {
    ImageResponsive,
    Icons
  },
  data() {
    return {
      date: this.$moment(new Date()).local().format(),
      masks: {
        input: "WWWW, D MMM YYYY",
      },
      city: {
        kabkoKode: "58a2fc6ed39fd083f55d4182bf88826d",
        kabkoNama: "KOTA JAKARTA",
      },
      dateKode: this.$moment(new Date()).local().format("YYYY-MM-DD"),
      articleSwipperOption: {
        navigation: {
          prevEl: ".prev-slide-article",
          nextEl: ".next-slide-article",
        },
        breakpoints: {
          320: {
            slidesPerView: 1.8,
            spaceBetween: 8,
          },
          1023: {
            slidesPerGroup: 2,
            slidesPerView: 2,
            spaceBetween: 16,
          },
        },
      },
    }
  },
  watch: {
    date(newValue, oldValue) {
      this.dateKode = newValue
        ? this.$moment(newValue).local().format("YYYY-MM-DD")
        : this.$moment(new Date()).local().format("YYYY-MM-DD");
      if (
        this.$moment(newValue).month() !== this.$moment(oldValue).month() ||
        this.$moment(newValue).year() !== this.$moment(oldValue).year()
      ) {
        this.getJadwal(true);
      } else {
        this.getJadwal(false);
      }
    },
    city(newValue, oldValue) {
      if (
        newValue &&
        newValue.kabkoKode !== "58a2fc6ed39fd083f55d4182bf88826d"
      ) {
        this.$router.push({
          name: "religi-jadwal-sholat-slug",
          params: {
            slug: newValue.slug,
          },
        });
      }
    },
  },
  methods: {
    async getJadwal(firstLoad) {
      if (firstLoad) {
        await this.$axios
          .$post(`${config.CORE_SERVICE_API}ramadhan`, {
            year: this.$moment(this.dateKode).local().year().toString(),
            month: this.$moment(this.dateKode).local().month() + 1,
            prov: "c51ce410c124a10e0db5e4b97fc2af39",
            city: "58a2fc6ed39fd083f55d4182bf88826d",
          })
          .then((response) => {
            for (const key in response.data) {
              if (key == this.dateKode) {
                let data = {
                  imsak: response.data[key].imsak,
                  subuh: response.data[key].subuh,
                  dzuhur: response.data[key].dzuhur,
                  ashar: response.data[key].ashar,
                  maghrib: response.data[key].maghrib,
                  isya: response.data[key].isya,
                };
                this.$store.commit("ramadan/SET_JADWAL", {
                  dataSalat: data,
                  tanggal: response.data[key].tanggal,
                });
              }
            }
            this.$store.commit("ramadan/SET_JADWAL_BULANAN", response.data);
          })
          .catch((e) => {
            console.log(e.response);
          });
      } else {
        let data = {
          imsak: this.$store.state.ramadan.jadwalBulanan.items[this.dateKode].imsak,
          subuh: this.$store.state.ramadan.jadwalBulanan.items[this.dateKode].subuh,
          dzuhur: this.$store.state.ramadan.jadwalBulanan.items[this.dateKode].dzuhur,
          ashar: this.$store.state.ramadan.jadwalBulanan.items[this.dateKode].ashar,
          maghrib: this.$store.state.ramadan.jadwalBulanan.items[this.dateKode].maghrib,
          isya: this.$store.state.ramadan.jadwalBulanan.items[this.dateKode].isya,
        };
        this.$store.commit("ramadan/SET_JADWAL", {
          dataSalat: data,
          tanggal: this.$store.state.ramadan.jadwalBulanan.items[this.dateKode].tanggal,
        });
      }
    },
    async getCities() {
      await this.$axios
        .$get(`${config.CORE_SERVICE_API}ramadhan`)
        .then((response) => {
          this.$store.commit("ramadan/SET_KOTA", response);
          return response;
        })
        .catch((e) => {
          return e.response;
        });
    },
  },
  mounted() {
    this.getCities()
    this.getJadwal(true)
    this.$store.dispatch("articles/getArticlesByTagsInfinity", {
      tagsSlug: 'ramadan-dan-religi',
      page: 1,
      limit: 10
    })
  }
}
</script>

<style lang="scss" scoped>
.imsak-wrapper {
  .imsak {
    background-image: url(../../static/storage/images/ramadan/bg-imsak.webp);
    background-size: cover;
    border-radius: 4px;
    padding: 16px 32px;
    display: flex;
    gap: 16px;
    @media only screen and (max-width: 1024px) {
      flex-direction: column;
      padding: 16px;
      background-image: url(../../static/storage/images/ramadan/bg-imsak-mobile.webp);
    }

    .info {
      width: 25%;
      display: flex;
      overflow: hidden;
      flex-direction: column;
      gap: 8px;
      @media only screen and (max-width: 1024px) {
        width: 100%;
        justify-content: space-between;
        flex-direction: row;
        align-items: center;
      }

      .logo {
        width: 195.53px;
      }

      .description {
        font-size: 13px;
        color: #FFF;
        opacity: .8;
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-line-clamp: 4;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        @media only screen and (max-width: 1024px) {
          display: none;
        }
      }

      .cta {
        display: flex;
        gap: 8px;
        width: fit-content;
        padding: 8px 12px;
        border-radius: 4px;
        background-color: #FFD451;
        font-size: 13px;
        color: #0C0B0D;
        img {
          width: 18px;
          height: 19.49px;
        }
        @media only screen and (max-width: 1024px) {
          padding: 4px 12px;
          gap: 4px;
        }
      }
    }

    .imsak-quran {
      width: 37.5%;
      overflow: hidden;
      display: flex;
      flex-direction: column;
      gap: 8px;
      justify-content: space-between;

      @media only screen and (max-width: 1024px) {
        width: 100%;
        overflow: unset;
      }

      .imsak-card {
        border-radius: 8px;
        display: flex;
        background-color: #F5F6FA;
        height: 124px;
        flex-direction: column;
        padding: 8px;
        gap: 8px;
        @media only screen and (max-width: 1024px) {
          margin-top: 8px;
        }

        .title {
          display: flex;
          gap: 8px;
          align-items: center;
          justify-content: center;
          font-size: 14px;
          font-weight: 500;
        }

        .options {
          display: flex;
          gap: 8px;

          .v-select {
            width: 100%;

            ::v-deep {
              .vs__dropdown-toggle {
                height: 24px !important;
                box-shadow: 0px 0px 5px 0px #2121211A;
              }

              .vs__actions svg {
                scale: 65%;
              }

              .vs__search,
              .vs__search:focus {
                font-size: 12px;
              }

              .vs__dropdown-menu {
                font-size: 12px !important;
                overflow: hidden;
              }

              .vs__selected {
                font-size: 10px;
                color: #202020;
                overflow: hidden;
                text-overflow: ellipsis;
                -webkit-line-clamp: 1;
                display: -webkit-box;
                -webkit-box-orient: vertical;
              }
            }
          }

          .date-picker {
            width: 100%;
            position: relative;
            display: inline-block;
            cursor: pointer;

            .selected-date {
              position: relative;
              background: #ffffff;
              padding: 2.8px 10px;
              border-radius: 4px;
              display: flex;
              justify-content: space-between;
              align-items: center;
              box-shadow: 0px 0px 4px rgb(0 0 0 / 20%);

              .value-date {
                font-size: 12px;
                color: #202020;
              }
            }

            .calendar-icon {
              width: 12px;
            }
          }

          svg {
            width: 25px;
            height: auto;
            cursor: pointer;
          }
        }

        .imsak-card-wrapper {
          background-color: #ffffff;
          box-shadow: 0px 0px 5px 0px #2121211A;
          display: flex;
          justify-content: space-between;
          border-radius: 8px;
          padding: 4px 30px;
          height: 43.99px;
          overflow: hidden;

          .imsak-card-item {
            display: flex;
            flex-direction: column;
            align-items: center;

            span {
              &:nth-child(1) {
                font-size: 12px;
                font-weight: 500;
                color: #202020;
                text-transform: capitalize;
              }

              &:nth-child(2) {
                font-size: 12px;
                color: #202020;
              }
            }
          }
        }
      }

      .quran-card {
        width: 100%;
        background-color: #F5F6FA;
        border-radius: 8px;
        padding: 6px 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: #202020;
        font-size: 14px;
        @media only screen and (max-width: 1024px) {
          display: none;
        }
      }
    }

    .articles {
      width: calc(100% - (25% + 37.5%));
      overflow: hidden;
      @media only screen and (max-width: 1024px) {
        width: 100%;
        margin-top: 8px;
      }
      .articles-wrapper {
        position: relative;
        height: 100%;

        .article-item {
          display: flex;
          flex-direction: column;
          gap: 8px;

          .article-image {
            border-radius: 4px;
            height: 103px;
          }

          span {
            font-size: 13px;
            color: #F5F6FA;
            overflow: hidden;
            text-overflow: ellipsis;
            -webkit-line-clamp: 3;
            display: -webkit-box;
            -webkit-box-orient: vertical;
          }
        }

        .prev-slide-article {
          position: absolute;
          top: 37%;
          width: 32px;
          height: 32px;
          background-color: #fff;
          border-radius: 100%;
          left: 4px;
          transform: translateY(-50%);
          z-index: 1;

          ::v-deep {
            svg {
              width: 32px;
              height: 32px;
            }
          }
        }

        .next-slide-article {
          z-index: 1;
          position: absolute;
          top: 37%;
          right: 4px;
          width: 32px;
          height: 32px;
          background-color: #fff;
          border-radius: 100%;
          transform: translateY(-50%);

          ::v-deep {
            svg {
              width: 32px;
              height: 32px;
            }
          }
        }
      }
    }
  }
}
</style>
