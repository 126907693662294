@@ -1,80 +0,0 @@
<template>
  <div class="article-text">
    <Shimmer height="500px" width="100%" class="mb-5 thumbnail" />
    <Shimmer height="20px" width="100%" class="mb-2" />
    <Shimmer height="20px" width="100%" class="mb-2" />
    <Shimmer height="20px" width="80%" class="mb-4" />
    <Shimmer height="20px" width="100%" class="mb-2" />
    <Shimmer height="20px" width="100%" class="mb-2" />
    <Shimmer height="20px" width="80%" class="mb-4" />
    <Shimmer height="20px" width="100%" class="mb-2" />
    <Shimmer height="20px" width="100%" class="mb-2" />
    <Shimmer height="20px" width="80%" />
  </div>
</template>

<script>
import Shimmer from "@/components/Shimmer";

export default {
  components: {
    Shimmer,
  },
};
</script>

<style lang="scss" scoped>
::v-deep .Shimmer {
  border-radius: 6px;
}
.article-text {
  @media only screen and (max-width: 1024px) {
    padding: 20px;
  }
  .categories {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    .category {
      @media only screen and (max-width: 1024px) {
        width: 100px;
      }
    }
    .dots {
      font-size: 20px;
      margin: 0 20px;
      @media only screen and (max-width: 1024px) {
        margin: 0 10px;
      }
    }
  }
  .title {
    margin-bottom: 10px;
    @media only screen and (max-width: 1024px) {
      width: 100% !important;
    }
  }
  .sosmed {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;
    @media only screen and (max-width: 1024px) {
      flex-direction: column;
      align-items: flex-start;
      margin-bottom: 16px;
    }
    .editor {
      @media only screen and (max-width: 1024px) {
        margin-bottom: 10px;
      }
    }
    .sosmed-wrapper {
      .box {
        border-radius: 50%;
        margin-left: 5px;
        @media only screen and (max-width: 1024px) {
          height: 30px;
          width: 30px;
        }
      }
    }
  }
  .thumbnail {
    @media only screen and (max-width: 1024px) {
      height: 200px !important;
      width: 100%;
      margin-bottom: 24px !important;
    }
  }
}
</style>
