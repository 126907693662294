<template>
  <div class="card-wrapper">
    <div class="card" v-for="(item, index) in 4" :key="index">
      <div class="img-wrapper">
        <Shimmer height="100%" width="100%" />
      </div>
      <div class="card-body d-flex flex-column justify-content-between">
        <Shimmer width="200px" height="20px" class="shimmer mb-1" />
        <Shimmer width="100%" height="20px" class="shimmer mb-3" />
        <Shimmer width="320px" height="100px" class="shimmer mb-3" />
        <div class="d-flex justify-content-between">
          <Shimmer width="100px" height="20px" class="shimmer" />
          <Shimmer width="100px" height="20px" class="shimmer" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Shimmer from "../Shimmer";
export default {
  components: { Shimmer },
};
</script>

<style lang="scss" scoped>
.card-wrapper {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  width: 100%;
  column-gap: 16px;
  .card {
    border-radius: 10px;
    height: 450px;
    overflow: hidden;
    .img-wrapper {
      width: 100%;
      height: 200px;
    }
    .shimmer {
      border-radius: 5px;
    }
  }
}
</style>
