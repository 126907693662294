@@ -1,80 +0,0 @@
<template>
  <div>
    <div class="row mb-3">
      <div class="col-md-3" v-for="(item, index) in 4" :key="index">
        <div class="card">
          <div class="img-wrapper">
            <Shimmer height="180px" width="100%" />
          </div>
          <div class="card-body d-flex flex-column justify-content-between">
            <Shimmer width="100%" height="20px" class="title shimmer mb-3" />
            <Shimmer width="100%" height="100px" class="body shimmer mb-3" />
            <div class="d-flex justify-content-between">
              <Shimmer width="100px" height="20px" class="btn1 shimmer" />
              <Shimmer width="100px" height="20px" class="btn2 shimmer" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row mb-3">
      <div class="col-md-3" v-for="(item, index) in 4" :key="index">
        <div class="card">
          <div class="img-wrapper">
            <Shimmer height="180px" width="100%" />
          </div>
          <div class="card-body d-flex flex-column justify-content-between">
            <Shimmer width="100%" height="20px" class="title shimmer mb-3" />
            <Shimmer width="100%" height="100px" class="body shimmer mb-3" />
            <div class="d-flex justify-content-between">
              <Shimmer width="100px" height="20px" class="btn1 shimmer" />
              <Shimmer width="100px" height="20px" class="btn2 shimmer" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Shimmer from "@/components/Shimmer"

export default {
  components: {
    Shimmer
  }
}
</script>

<style lang="scss" scoped>
.card {
  border-radius: 10px;
  @media only screen and (max-width: 1024px) {
    margin-bottom: 20px;
    flex-direction: row;
  }
  .img-wrapper {
    @media only screen and (max-width: 1024px) {
      width: 40%;
    }
    span {
      @media only screen and (max-width: 1024px) {
        height: 100% !important;
      }
    }
  }
  .card-body {
    @media only screen and (max-width: 1024px) {
      width: 60%;
    }
    .title {
      @media only screen and (max-width: 1024px) {
        margin-bottom: 10px !important;
      }
    }
    .body {
      @media only screen and (max-width: 1024px) {
        height: 60px !important;
        margin-bottom: 10px !important;
      }
    }
    .btn1,
    .btn2 {
      @media only screen and (max-width: 1024px) {
        width: 70px !important;
      }
    }
  }
}
</style>
