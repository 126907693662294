@@ -1,80 +0,0 @@
<template>
  <div class="shimmer-card">
    <div class="img-wrapper">
      <Shimmer width="100%" class="image" />
    </div>
    <div class="card-body d-flex flex-column justify-content-between">
      <div class="d-flex justify-content-between mb-3">
        <Shimmer width="100px" height="20px" class="btn1 shimmer" />
        <Shimmer width="100px" height="20px" class="btn2 shimmer" />
      </div>
      <Shimmer width="100%" height="50px" class="body shimmer" />
    </div>
  </div>
</template>

<script>
import Shimmer from "@/components/Shimmer"

export default {
  components: {
    Shimmer
  }
}
</script>

<style lang="scss" scoped>
.shimmer-card {
  width: 280px;
  border-radius: 10px;
  margin-right: 16px;
  overflow: hidden;
  background: white;
  @media only screen and (max-width: 1024px) {
    display: flex;
    flex-direction: row;
    width: 100% !important;
    padding: 12px;
    gap: 12px;
    margin-bottom: 12px;
  }
  .img-wrapper {
    @media only screen and (max-width: 1024px) {
      width: 100px;
      height: 100px;
      flex-grow: 1;
      flex: inherit;
    }
    .image {
      height: 160px !important;
      @media only screen and (max-width: 1024px) {
        height: 100px !important;
      }
    }
  }
  .card-body {
    @media only screen and (max-width: 1024px) {
      padding: 0;
      gap: 12px;
    }
    div {
      @media only screen and (max-width: 1024px) {
        margin: 0 !important;
        gap: 12px;
      }
      .shimmer {
        @media only screen and (max-width: 1024px) {
          flex: 50%;
        }
      }
    }
    .body {
      @media only screen and (max-width: 1024px) {
        flex: inherit;
      }
    }
  }
}
</style>
