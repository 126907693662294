<template>
  <div class="article-wrapper">
    <div class="img-wrapper_ w-50">
      <Shimmer width="100%" height="350px" />
    </div>
    <div class="description_ w-50 px-5">
      <div class="schedule_ d-flex mb-3">
        <Shimmer width="180px" height="30px" class="rounded-pill" />
        <Shimmer width="180px" height="30px" class="rounded-pill mx-3" />
      </div>
      <Shimmer width="100%" height="50px" class="mb-3 rounded-top" />
      <Shimmer width="100%" height="120px" class="mb-3" />
      <Shimmer width="200px" height="60px" />
    </div>
  </div>
</template>

<script>
import Shimmer from "@/components/Shimmer"

export default {
  components: {
    Shimmer
  }
}
</script>

<style lang="scss">
.article-wrapper {
  .img-wrapper_ {
    @media only screen and (max-width: 1024px) {
      width: 100% !important;
    }
  }
  .description_ {
    @media only screen and (max-width: 1024px) {
      width: 100% !important;
      padding: 0 !important;
    }
    .schedule_ {
      @media only screen and (max-width: 1024px) {
        margin: 20px 0;
        justify-content: center;
      }
    }
  }
}
</style>
