@@ -1,80 +0,0 @@
<template>
  <div class="card">
    <div class="img-wrapper">
      <Shimmer height="90px" width="100%" />
    </div>
    <div class="card-body d-flex flex-column justify-content-between">
      <Shimmer width="30%" height="10px" class="body shimmer mb-1" />
      <Shimmer width="100%" height="40px" class="body shimmer-body mb-1" />
      <Shimmer width="50%" height="10px" class="body shimmer" />
    </div>
  </div>
</template>

<script>
import Shimmer from "@/components/Shimmer"

export default {
  components: {
    Shimmer
  }
}
</script>

<style lang="scss" scoped>
.card {
  width: 100%;
  height: 120px;
  display: flex;
  flex-direction: row;
  border-radius: 10px;
  margin-right: 16px;
  margin-bottom: 16px;
  overflow: hidden;
  @media only screen and (max-width: 1024px) {
    width: 100%;
    height: 150px;
  }
  .img-wrapper {
    width: 40%;
    ::v-deep span {
      height: 100% !important;
    }
  }
  .card-body {
    width: 60%;
    ::v-deep .shimmer {
      height: 15% !important;
    }
    ::v-deep .shimmer-body {
      height: 60% !important;
    }
  }
}
</style>
