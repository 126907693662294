<template>
  <div class="pesantren-kilat" :class="{'bg-color-blank': isHomepage}">
    <div class="container">
      <div class="content-wrapper">
        <div class="content-peskil">
          <div class="copy">
            <img class="logo-pk" src="/storage/images/ramadan/logo-pesantren-kilat.png" />
            <div class="box-wrapper desktop-flex">
              <div class="box">30-31 Maret 2024</div>
              <div class="box">The Brickhall at Fatmawati City Center</div>
            </div>
            <div class="box-caption">NGAJI BERSAMA | PODCAST DAN STAND UP COMEDY |<br>KELAS SHARING | KULTUM & BUKBER | BAZAR</div>
          </div>
          <div class="list-card-pesantren-kilat slider" :class="{ setHeight: images.length > 1 }">
            <div v-swiper:mySwiperPesantrenKilat="swiperOptions.pesantren_kilat">
              <div class="swiper-wrapper">
                <img v-for="(item, index) in images" :key="`img-slider-${index}`" :src="item"
                  :fromUrl="false" class="img-slider swiper-slide" :class="{ setPadding: images.length > 1 }" />
              </div>
              <div class="swiper-pagination" v-if="images.length > 1"></div>
            </div>
          </div>
          <div class="btn-wrapper">
            <p class="location mobile">
              THE BRICKHALL AT FATMAWATI CITY CENTER <br />30-31 Maret 2024
              <div class="box-caption">NGAJI BERSAMA | PODCAST DAN STAND UP COMEDY |<br>KELAS SHARING | KULTUM & BUKBER | BAZAR</div>
            </p>
            <a href="http://event.narasi.tv/pesantren-kilat" target="_blank" rel="noopener" class="btn-beli-tiket-pk">
              Pesan Tiket
              <Icons name="tiket" />
            </a>
          </div>
        </div>
        <div class="articles-wrapper" :class="articles ? 'mt-16' : '' " v-swiper:articleSwipper="articleSwipperOption">
          <div class="swiper-wrapper">
            <nuxt-link :to="{
                  name: article.isText
                    ? 'read-channel-slug'
                    : 'video-channel-slug',
                  params: {
                    channel: article.channel ? article.channel.slug : '',
                    slug: article.slug,
                  },
                }" class="swiper-slide article-item" v-for="(article, index) in articles" :key="`article-item-${index}`">
              <ImageResponsive
                width="252"
                height="141"
                :imageUrl="article.thumbnail.large"
                :fromUrl="true" class="article-image" />
              <span>{{ article.title }}</span>
            </nuxt-link>
          </div>
          <div class="prev-slide-article">
            <Icons name="arrow-left" />
          </div>
          <div class="next-slide-article">
            <Icons name="arrow-right" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ImageResponsive from "../ImageResponsive.vue";
import Icons from "../Icons.vue";
export default {
  props: {
    isHomepage: false,
    articles: []
  },
  components: {
    ImageResponsive,
    Icons,
  },
  data() {
    return {
      images: [
        "/storage/images/ramadan/ngabers.webp",
      ],
      articleSwipperOption: {
        navigation: {
          prevEl: ".prev-slide-article",
          nextEl: ".next-slide-article",
        },
        breakpoints: {
          320: {
            slidesPerView: 1.8,
            spaceBetween: 8,
          },
          1023: {
            slidesPerGroup: 5,
            slidesPerView: 5,
            spaceBetween: 12,
          },
        },
      },
      swiperOptions: {
        pesantren_kilat: {
          pagination: {
            el: ".swiper-pagination",
            type: "bullets",
            enabled: true,
            clickable: true,
          },
          // autoplay: {
          //   delay: 2000,
          // },
          autoHeight: true,
          calculateHeight: true,
          observer: true,
          effect: "fade",
          observeParents: true,
          watchSlidesVisibility: true,
          // navigation: {
          //   prevEl: ".prev-pesantren-kilat",
          //   nextEl: ".next-pesantren-kilat",
          // },
          breakpoints: {
            1023: {
              slidesPerView: 1,
              spaceBetween: 0,
            },
            320: {
              slidesPerView: 1,
              spaceBetween: 0,
            },
          },
        },
      },
    };
  },
};
</script>

<style lang="scss" scoped>
.bg-color-blank {
  background-color: unset !important;
  .articles-wrapper {
    padding: 0 !important;
  }
}
.desktop {
  display: block !important;

  @media only screen and (max-width: 768px) {
    display: none !important;
  }
}

.desktop-flex {
  display: flex !important;

  @media only screen and (max-width: 768px) {
    display: none !important;
  }
}

.mobile {
  display: none !important;

  @media only screen and (max-width: 768px) {
    display: block !important;
  }
}

.mobile-flex {
  display: none !important;

  @media only screen and (max-width: 768px) {
    display: flex !important;
  }
}

.pesantren-kilat {
  background-color: #ffffff;

  @media only screen and (max-width: 1024px) {
    background-color: #f1f1f1;
  }

  .container {
    @media only screen and (max-width: 768px) {
      padding: 0;
    }

    .content-wrapper {
      background: url(../../static/storage/images/ramadan/bg-pk-home-desktop.webp);
      background-repeat: no-repeat;
      background-size: cover;
      padding: 16px 32px;
      border-radius: 4px;
      @media only screen and (max-width: 1024px) {
        background: url(../../static/storage/images/ramadan/bg-pk-home-mobile.png);
        padding: 0;
        border: 1px solid #c9c9c9;
        margin: 0 12px;
      }
    }

    .content-peskil {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: space-between;
      overflow: hidden;

      @media only screen and (max-width: 1024px) {
        background: url(../../static/storage/images/ramadan/bg-pk-home-container-tablet.png);
        background-repeat: no-repeat;
        background-size: cover;
        flex-direction: row;
        padding: 8px;
        gap: 16px;
      }

      @media only screen and (max-width: 768px) {
        background: url(../../static/storage/images/ramadan/bg-pk-home-container-mobile.png);
        background-repeat: no-repeat;
        background-size: cover;
        flex-direction: column;
        padding: 16px 32px;
        gap: 16px;
        border-radius: 8px;
      }

      .copy {
        width: 45%;
        border-right: 1px solid #d8d8d8;

        @media only screen and (max-width: 1024px) {
          width: 40%;
          border: none;
        }

        @media only screen and (max-width: 768px) {
          width: 100%;
          border: none;
        }

        .logo-pk {
          width: 70%;
          height: auto;
          margin-bottom: 8px;
        }

        .box-caption {
          margin-top: 8px;
          font-size: 11px;
          font-weight: 700;
          font-family: "Gotham-Bold", Roboto;
          color: #1B5786;
          text-transform: capitalize;
          line-height: 14.3px;
          @media only screen and (max-width: 1024px) {
            display: none;
            // overflow: hidden;
            // text-overflow: ellipsis;
            // -webkit-line-clamp: 1;
            // display: -webkit-box;
            // -webkit-box-orient: vertical;
          }
        }

        .box-wrapper {
          display: flex;
          gap: 8px;

          @media only screen and (max-width: 1024px) {
            flex-direction: column;
          }

          .box {
            background-color: #1b5482;
            color: #ffffff;
            font-size: 14px;
            font-family: "Gotham", Roboto;
            padding: 4px 8px;
            border-radius: 2px;

            @media only screen and (max-width: 1024px) {
              font-size: 10px;
              width: fit-content;
            }
          }

        }
      }

      .slider {
        position: absolute;
        top: 50%;
        left: 61%;
        transform: translate(-50%, -50%);
        width: 27%;
        display: flex;
        justify-content: center;

        &.setHeight {
          height: 100%;
        }

        @media only screen and (max-width: 1024px) {
          width: 35%;
          left: 50%;
        }

        @media only screen and (max-width: 768px) {
          width: 100%;
          position: relative;
          top: unset;
          left: unset;
          transform: unset;
        }

        .img-slider {
          width: 100%;
          height: auto;

          &.setPadding {
            padding: 8px 8px 0;
          }
        }

        ::v-deep .swiper-container {
          @media only screen and (max-width: 1024px) {
            width: 100%;
            overflow: visible;
          }

          .swiper-pagination {
            position: absolute;
            bottom: 5px;

            @media only screen and (max-width: 1024px) {
              position: relative;
              margin-top: 8px;
            }

            .swiper-pagination-bullet {
              width: 4px;
              height: 4px;
              border-radius: 50rem;
              background: rgba(27, 87, 136, 0.5);

              &.swiper-pagination-bullet-active {
                background-color: #1c5888;
              }
            }
          }
        }
      }

      .btn-wrapper {
        @media only screen and (max-width: 768px) {
          width: 100%;
          text-align: center;
        }

        .location {
          @media only screen and (max-width: 1024px) {
            font-size: 11px;
            font-weight: 700;
            font-family: "Gotham-Bold", Roboto;
            color: #1b5482;
          }
        }

        .box-caption {
          display: none;
          @media only screen and (max-width: 1024px) {
            display: block;
            text-align: center;
            margin-top: 8px;
            font-size: 9px;
            font-weight: 700;
            font-family: "Gotham", Roboto;
            color: #1B5786;
            line-height: 13px;
            overflow: hidden;
            text-overflow: ellipsis;
            -webkit-line-clamp: 2;
            display: -webkit-box;
            -webkit-box-orient: vertical;

          }
        }

        .btn-beli-tiket-pk {
          background-color: #0095b8;
          padding: 8px 24px;
          border-radius: 50rem;
          font-size: 16px;
          font-family: "Gotham", Roboto;
          font-weight: 700;
          color: #ffffff;
          display: inline-flex;
          align-items: center;
          gap: 3px;
          cursor: pointer;

          &:hover {
            color: #ffffff !important;
          }

          @media only screen and (max-width: 1024px) {
            font-size: 14px;
          }

          svg {
            width: 22px;
            height: auto;
          }
        }
      }
    }
    .articles-wrapper {
      position: relative;
      @media only screen and (max-width: 1024px) {
        padding: 8px;
      }
      &.mt-16 {
        margin-top: 16px;
      }
      .article-item {
        display: flex;
        flex-direction: column;
        gap: 4px;
        .article-image {
          border-radius: 4px;
        }
        span {
          font-size: 13px;
          color: #1B5482;
          font-family: "Gotham", 'sans';
          overflow: hidden;
          text-overflow: ellipsis;
          -webkit-line-clamp: 2;
          display: -webkit-box;
          -webkit-box-orient: vertical;
        }
      }
      .prev-slide-article {
          position: absolute;
          top: 37%;
          width: 32px;
          height: 32px;
          background-color: #fff;
          // filter: drop-shadow(0px 0px 8px rgba(0, 0, 0, 0.25));
          border-radius: 100%;
          left: 4px;
          transform: translateY(-50%);
          z-index: 1;

          ::v-deep {
            svg {
              width: 32px;
              height: 32px;
            }
          }

          @media only screen and (max-width: 1024px) {
            display: none;
          }
        }

        .next-slide-article {
          z-index: 1;
          position: absolute;
          top: 37%;
          right: 4px;
          width: 32px;
          height: 32px;
          background-color: #fff;
          // filter: drop-shadow(0px 0px 8px rgba(0, 0, 0, 0.25));
          border-radius: 100%;
          transform: translateY(-50%);

          ::v-deep {
            svg {
              width: 32px;
              height: 32px;
            }
          }

          @media only screen and (max-width: 1024px) {
            display: none;
          }
        }
    }
  }
}
</style>
