<template>
  <div class="card-wrapper">
    <Shimmer width="100%" height="343px" class="is-desktop" />
    <Shimmer width="100%" height="230px" class="is-mobile" />
  </div>
</template>

<script>
import Shimmer from "../Shimmer";
export default {
  components: { Shimmer },
};
</script>

<style lang="scss" scoped>
.card-wrapper {
  width: 100%;
  display: flex;
  .shimmer {
    border-radius: 10px;
    margin-right: 20px;
  }
}
</style>
