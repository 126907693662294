var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"pesantren-kilat",class:{'bg-color-blank': _vm.isHomepage}},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"content-wrapper"},[_c('div',{staticClass:"content-peskil"},[_vm._m(0),_vm._v(" "),_c('div',{staticClass:"list-card-pesantren-kilat slider",class:{ setHeight: _vm.images.length > 1 }},[_c('div',{directives:[{name:"swiper",rawName:"v-swiper:mySwiperPesantrenKilat",value:(_vm.swiperOptions.pesantren_kilat),expression:"swiperOptions.pesantren_kilat",arg:"mySwiperPesantrenKilat"}]},[_c('div',{staticClass:"swiper-wrapper"},_vm._l((_vm.images),function(item,index){return _c('img',{key:`img-slider-${index}`,staticClass:"img-slider swiper-slide",class:{ setPadding: _vm.images.length > 1 },attrs:{"src":item,"fromUrl":false}})}),0),_vm._v(" "),(_vm.images.length > 1)?_c('div',{staticClass:"swiper-pagination"}):_vm._e()])]),_vm._v(" "),_c('div',{staticClass:"btn-wrapper"},[_vm._m(1),_vm._m(2),_vm._v(" "),_c('p'),_vm._v(" "),_c('a',{staticClass:"btn-beli-tiket-pk",attrs:{"href":"http://event.narasi.tv/pesantren-kilat","target":"_blank","rel":"noopener"}},[_vm._v("\n            Pesan Tiket\n            "),_c('Icons',{attrs:{"name":"tiket"}})],1)])]),_vm._v(" "),_c('div',{directives:[{name:"swiper",rawName:"v-swiper:articleSwipper",value:(_vm.articleSwipperOption),expression:"articleSwipperOption",arg:"articleSwipper"}],staticClass:"articles-wrapper",class:_vm.articles ? 'mt-16' : ''},[_c('div',{staticClass:"swiper-wrapper"},_vm._l((_vm.articles),function(article,index){return _c('nuxt-link',{key:`article-item-${index}`,staticClass:"swiper-slide article-item",attrs:{"to":{
                name: article.isText
                  ? 'read-channel-slug'
                  : 'video-channel-slug',
                params: {
                  channel: article.channel ? article.channel.slug : '',
                  slug: article.slug,
                },
              }}},[_c('ImageResponsive',{staticClass:"article-image",attrs:{"width":"252","height":"141","imageUrl":article.thumbnail.large,"fromUrl":true}}),_vm._v(" "),_c('span',[_vm._v(_vm._s(article.title))])],1)}),1),_vm._v(" "),_c('div',{staticClass:"prev-slide-article"},[_c('Icons',{attrs:{"name":"arrow-left"}})],1),_vm._v(" "),_c('div',{staticClass:"next-slide-article"},[_c('Icons',{attrs:{"name":"arrow-right"}})],1)])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"copy"},[_c('img',{staticClass:"logo-pk",attrs:{"src":"/storage/images/ramadan/logo-pesantren-kilat.png"}}),_vm._v(" "),_c('div',{staticClass:"box-wrapper desktop-flex"},[_c('div',{staticClass:"box"},[_vm._v("30-31 Maret 2024")]),_vm._v(" "),_c('div',{staticClass:"box"},[_vm._v("The Brickhall at Fatmawati City Center")])]),_vm._v(" "),_c('div',{staticClass:"box-caption"},[_vm._v("NGAJI BERSAMA | PODCAST DAN STAND UP COMEDY |"),_c('br'),_vm._v("KELAS SHARING | KULTUM & BUKBER | BAZAR")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"location mobile"},[_vm._v("\n            THE BRICKHALL AT FATMAWATI CITY CENTER "),_c('br'),_vm._v("30-31 Maret 2024\n            ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"box-caption"},[_vm._v("NGAJI BERSAMA | PODCAST DAN STAND UP COMEDY |"),_c('br'),_vm._v("KELAS SHARING | KULTUM & BUKBER | BAZAR")])
}]

export { render, staticRenderFns }