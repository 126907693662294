<template>
  <div class="d-flex">
    <Shimmer
      v-for="(item, index) in 7"
      :key="index"
      height="40px"
      class="rounded-pill mx-2 category"
      width="180px"
    />
  </div>
</template>

<script>
import Shimmer from "../Shimmer";
export default {
  components: { Shimmer },
};
</script>

<style lang="scss" scoped>
::v-deep .category {
  width: 180px;
  height: 40px;
  @media only screen and (max-width: 1024px) {
    width: 150px !important;
    height: 30px !important;
  }
}
</style>
