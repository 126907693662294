<template>
  <div class="card-wrapper">
    <Shimmer
      v-for="(item, index) in 6"
      :key="index"
      width="100%"
      height="120px"
      class="shimmer"
    />
  </div>
</template>

<script>
import Shimmer from "../Shimmer";
export default {
  components: { Shimmer },
};
</script>

<style lang="scss" scoped>
.card-wrapper {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  padding: 20px;
  .shimmer {
    border-radius: 10px;
    margin-bottom: 20px;
  }
}
</style>
