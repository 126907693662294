<template>
  <div class="card-wrapper">
    <div class="title">
      <Shimmer width="90%" height="20px" class="shimmer" />
      <Shimmer width="80%" height="20px" class="shimmer" />
      <Shimmer width="30%" height="20px" class="shimmer" />
    </div>
    <div class="materi">
      <Shimmer width="100%" height="20px" class="shimmer" />
    </div>
  </div>
</template>

<script>
import Shimmer from "../Shimmer";
export default {
  components: { Shimmer },
};
</script>

<style lang="scss" scoped>
.card-wrapper {
  width: 100%;
  padding: 20px;
  display: flex;
  .title {
    width: 75%;
  }
  .materi {
    width: 25%;
  }
  .shimmer {
    border-radius: 5px;
    margin-bottom: 10px;
  }
}
</style>
